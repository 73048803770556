@import '../../../assets/css/partials/mixins.scss';
@import '../../../assets/css/partials/vars.scss';
@import '../../../assets/css/partials/extends.scss';

.stylebox {
    margin-bottom: 40px;
}


.stylebox-top {
    position: relative;
    padding: 21px 28px;

    .icon-delete {
        position: absolute;
        right:-10px;
        top:-10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @extend %userCursor;
        text-align: center;
        line-height: 30px;
        color:$whiteColor;
        font-size: 10px;
        background-color: $darkColor-1;
    }
}

.stylebox-title {
    position: relative;
    @include font("Lato", 22, 32, $whiteColor, initial, 900);
    width:50%;

    input {
      font-weight: 900 !important;
      border-color:transparent !important;
      padding-right: 10px;

      &:focus {
         border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
      }
    }
    &:hover {
      input {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
      }
    }
    
    .icon {
      position: absolute;
      right:-16px;
      top:18px;
      color:$whiteColor;
      font-size: 14px;
    }
}

.toggle-stylebox-btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 34px;
    color:$whiteColor;
    font-size: 8px;
    border:2px solid rgba(255,255,255, 0.1);
    @extend %inlineMiddle;
    @extend %userCursor;
}

.main-stylebox {
    border-top:1px solid rgba(255,255,255,0.1);
}

.stylebox-subtitle {
    height: 42px;
    padding-left: 30px;
    background-color: rgba(18, 25, 45, 0.4);

    >span {
      vertical-align: middle;
      @include font("Lato", 15, 40, $whiteColor, uppercase, 500);
    }
}

.tags-wrapper {
    margin-top: 30px;
    .stylebox-subtitle {
        position: relative;
        margin-right: 30px;
        border-radius: 0 30px 30px 0;
        .btn {
            position: absolute;
            right:30px;
            top:12px;
        }
    }
}

/*tags*/


.tags-main {
    margin:30px 30px 0 30px;
}

.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    background-color: $whiteColor;
    border-radius: 5px;
    font-size: 1em;
    line-height: 1.2;
    cursor: text;
  }
  
  .react-tags.is-focused {
    border-color: #B1B1B1;
  }
  
  .react-tags__selected {
    display: inline;
  }

  .react-tags__selected-tag {
    display: inline-block;
    margin: 0 8px 6px 0;
    padding: 6px 8px;
    height: 28px;
    border-radius: 30px;
    background-color: rgba(28, 36, 62, 0.1);
    @include font("Lato", 16, 0, #15151d, initial, 500);
    
  }
  
  .react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
    @extend %userCursor;
  }
  
  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #B1B1B1;
  }
  
  .react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }
  
  }
  
  .react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__search input::-ms-clear {
    display: none;
  }
  
  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__suggestions {
      width: 240px;
    }
  
  }
  
  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  
  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }

/**/

.stylebox-styling {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 30px;
  .stylebox-subtitle {
    margin-bottom: 30px;
  }
}

.fav-style {
  background-color: rgba(255, 255, 255, 0.1);
  width: 32px;
  font-size: 16px;
  height: 32px;
  border-radius: 50%;
  line-height: 30px;
  margin-right: 15px;
  color:$whiteColor;
  text-align: center;
  @extend %inlineMiddle;
  @extend %userCursor;
}

.active-fav {
  background-color: rgba(217, 192, 26, 0.2);
  color:#d9c01a;
}