%inlineMiddle {
    display: inline-block;
    vertical-align: middle;
}

%userCursor {
    cursor: pointer;
    user-select: none;
}

%hide {
    visibility: hidden;
    opacity: 0;
}

%show {
    visibility: visible;
    opacity: 1;
}