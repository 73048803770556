@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';

.unsigned-title {
    @include font("Lato", 25, 26, $whiteColor, initial, 300);
    margin-bottom: 25px;
}

.unsigned {
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
}

.unsigned-form {
    
    .btn {
        width: 100%;
        margin-top: 8px;
    }
}

.field-wrapper {
    position: relative;
}

.show {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 14px;
}


.form-group {
    text-align: left;
    position: relative;
    margin-bottom: 28px;
    label {
        display: block;
        @include font("Lato", 14, 32, $whiteColor, initial, 700);
    }
    input {
        width: 100%;
        height:45px;
        border-radius: 5px;
        border:0;
        outline: none;
        padding-left:15px; 
        @include font("Lato", 16, 36, rgba(0,0,0,1), initial, 500);

        ::-webkit-input-placeholder {
            color:rgba(0,0,0,0.5);
        }
        :-ms-input-placeholder {
            color:rgba(0,0,0,0.5);
        }

    }
    .input-error {
        position: absolute;
        left:0;
        bottom: -30px;
        white-space: nowrap;
        @include font("Lato", 12, 32, #c62327, capitalize, 700);
    }
    > .row {
        margin-left: -.9375rem;
        margin-right: -.9375rem;
    }
    a {
        @include font("Lato", 14, 32, $whiteColor, initial, 400);
    }
}

.unsigned-info {
    margin-top: 32px;
}

.unsigned-txt {
    @include font("Lato", 16, 26, $whiteColor, initial, 400);

    a {
        margin-left: 5px;
        color:$whiteColor;
        font-weight: 700;
    }
}

.unsigned-btn-group {
    margin-top: 42px;
    >span {
        display: block;
        position: relative;
        margin-bottom: 24px;
        text-align: center;
        @include font("Lato", 18, 26, $whiteColor, initial, 400);

        &:before {
            content: '';
            position: absolute;
            left:0;
            top:50%;
            background-color: rgba(255,255,255,0.1);
            width:110px;
            height: 1px;
        }
        &:after {
            content: '';
            position: absolute;
            right:0;
            top:50%;
            background-color: rgba(255,255,255,0.1);
            width:110px;
            height: 1px;
        }
    }
    .btn {
        width: 174px;
    }
    >div {
        display: flex;
        justify-content: space-between;
    }
}

/**/

.premiumsignup {
    position: relative;
    padding-bottom: 100px;

    >.row {
        position: relative;
        &::before {
            content:'';
            position: absolute;
            left:-70px;
            top:-55px;
            width:122px;
            height: 94px;
            opacity: 0.1;
            background-image: url('../../assets/img/sign-decor.png');
            background-repeat:no-repeat; 
        }
        &::after {
            content:'';
            position: absolute;
            right:-70px;
            bottom:-55px;
            width:182px;
            height: 164px;
            opacity: 0.1;
            background-image: url('../../assets/img/sign-decor.png');
            background-repeat:no-repeat;
        }
    }
}

.premium-title {
    @include font("Rajdhani", 60, 62, $whiteColor, uppercase, 300);

    b {
        display: inline-block;
        font-weight:700;
        line-height: 61px;
        background-color: $purpleColor-1;
        padding-left: 8px;
        padding-right: 9px;
        padding-top: 4px;
    }
}

.premium-descrption {
    margin-top: 12px;
    padding-right: 50px;
    @include font("Lato", 20, 36, #8c96b1, initial, 300);
}

.video-play {
    position: absolute;
    left:calc(50% - 40px);
    top:calc(50% - 40px);
    padding-left: 10px;
    border-radius: 50%;
    background-image: -moz-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    background-image: -webkit-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    background-image: -ms-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    box-shadow: 0px 2px 15.04px 0.96px rgba(146, 97, 233, 0.5);
    width: 80px;
    height: 80px;
    line-height: 80px;
    z-index: 1;
}

.video-wrapper {
    @extend %inlineMiddle, %userCursor;
    margin-top:55px;
    text-align: center;

    span {
        @include font("Rajdhani", 22, 32, $whiteColor, uppercase, 700);
    }
}

.video-prev {
    position: relative;
    width:330px;
    height: 186px;
    background-image: url("../../assets/img/video-prev.jpg");
    background-size: contain;
    background-position:center center;
    border-radius: 20px; 
    overflow: hidden;
    margin-bottom: 15px;
        
    &:after {
        content:'';
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background-color: rgba(0,0,0,0.5)
    }
}

.content-wrapper.sign-up {
    position: relative;
    z-index: 1;
    margin-left: auto;
    max-width: 440px;
    width:100%;
    padding: 35px 30px 35px 30px;
}

.form-box-title {
    text-align: center;
    @include font("Rajdhani", 32, 42, $whiteColor, uppercase, 700);
}

.little-desc {
    text-align: center;
    margin-bottom: 16px;
    @include font("lato", 13, 26, $whiteColor, initial, 300);

    a {
        margin-left:5px;
        color:$greenColor-2;
    }
}

.features {
    margin-top:75px;
}

.features-item {
    display: flex;
    flex-flow: row;
    align-items:center;
    margin-bottom: 50px;
    span{
        @include font("lato", 22, 32, $whiteColor, initial, 400);
    }
    i {
        margin-right: 22px;
    }
}

.feature-n {
    max-width: 29px;
    flex:0 0 29px;
}

.feature-i {
    max-width: 55px;
    flex:0 0 55px;
}