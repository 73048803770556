@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';

.uploadfilesteps {
    padding-bottom: 100px;
}

.uploadfilecontent {
    background-color: $darkColor-2;
    padding: 40px;
    min-height: 417px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.09),0px 0px 32px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.upload-wraping {
    position: relative;
}

.upload-main-content {
    
}

.attention-wrapper {

    > i {
        color:rgba(247, 56, 114, 1);
        font-size: 62px;
        margin-bottom: 14px;
        @extend %inlineMiddle;
    }
}



.upload-main {

    > i {
        font-size: 66px;
        color:$whiteColor;
        margin-bottom: 14px;
        @extend %inlineMiddle;
    }
}

.upload-title {
    @include font("Rajdhani", 30, 32, $whiteColor, uppercase, 700);
}

.upload-description {
    max-width: 370px;
    margin: 7px auto 20px;
    @include font("Lato", 18, 28, $whiteColor, initial, 300);
}

.privacy-check {
    margin-top: 52px;
    input {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        @extend %inlineMiddle;
    }
    label {
        @extend %userCursor;
        @extend %inlineMiddle;
        @include font("Lato", 16, 28, $whiteColor, initial, 300);
    }
}

.upload-file-att-info {
    margin-top: 40px;
    @include font("Lato", 14, 28, $whiteColor, initial, 300);
    opacity: 0.3;
}

.upload-step-actions {
    margin-top: 45px;
}

.chooseFiles {
    display: block;
    width: 100%;
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    opacity: 0;
    @extend %userCursor;
}

.uploaded-icon {
    position: relative;
    margin-bottom: 17px;
    @extend %inlineMiddle;

    .icon-files {
        color:$whiteColor;
        font-size: 66px;
    }
    .icon-check {
        position: absolute;
        right:-8px;
        bottom:-6px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        line-height:24px;
        font-size: 9px;
        background-color: $greenColor-1;
        color:$whiteColor; 
        box-shadow: 0px 0px 15.04px 0.96px rgba(102, 254, 208, 0.41);
    }
}

.uploaded-actions {
    border-top:1px solid rgba(255,255,255, 0.1);
    margin-top: 16px;
    padding-top: 15px;

    >div {
        margin-top: 11px;
    }
}

.add-file {
    display: inline-flex;
    align-items: center;
    color: #00d7c0;
    font-size: 14px !important;
    font-family: 'Lato';
    font-weight: 900;
    text-transform:uppercase;
    transition: all 0.22s ease;
    border-bottom: 1px solid transparent;
    @extend %userCursor;
    border-radius: 0;
    padding-bottom: 2px;
    &:hover {
        border-color: #00d7c0;
    }
    i {
        margin-right: 8px;
    }
}

.cancel-file {
    color: $whiteColor;
    font-size: 14px !important;
    font-family: 'Lato';
    font-weight: 900;
    text-transform:uppercase;
    transition: all 0.22s ease;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    padding-bottom: 2px;
    @extend %userCursor;
    &:hover {
        border-color: $whiteColor;
    }
}

.count-uploaded {
    @include font("Rajdhani", 30, 32, $whiteColor, initial, 700);
}

.hide-inp {
    display: none;
}

