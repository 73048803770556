@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';

.profile-page {
    padding-bottom: 100px;
}

.change-profile {
    padding: 38px;
}

.profile-links {
    min-width:223px;
    padding-left: 24px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.profile-link {
    display: flex;
    align-items: center;
    position: relative;
    color:rgba(255,255,255,0.2);
    transition: all 0.25s ease;
    i {
        color:inherit;
    }
    span {
        margin-left: 15px;
        @include font("Lato", 16, 48, inherit, initial, 500);
    }
    &.active {
        color:$whiteColor;

        &:before {
            @extend %show;
        }
    }

    &:before {
        content:'';
        position: absolute;
        left:-24px;
        top:18px;
        width:3px;
        height:14px;
        transition: all 0.25s ease;
        background-color:$greenColor-2;
        @extend %hide;
    }
}

.change-profile {
    width:520px;
}