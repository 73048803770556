@import '../../../assets/css/partials/vars';

.btn-group{

  .btn:not(:last-child) {
    margin-right: 15px;
  }
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  outline: none;
  border: 0;
  height: 50px;
  border-radius: 5px;
  padding: 0 30px 0 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: transparent;

  span {
    font-family: 'Lato';
    font-weight:900;
    font-size: 14px;
  }
  &-rounded {
    border-radius:50px;
  }
  &-white{
    border:2px solid rgba(28, 36, 62, 0.2);
    color:$darkColor-1;

    &:hover {
      border-color:rgba(28, 36, 62, 0.3);
    }
    &:active {
      background-color: #e8e9eb;
    }
  }
  i ~ span{
    margin-left: 8px;
  }
  span ~ i{
    margin-left: 8px;
  }
  &-green{
    border:2px solid $greenColor-2;
    background-color: rgba(0, 215, 192, 0.1);
    color:$greenColor-2;
    box-shadow: 0 15px 16.92px 1.08px rgba(0, 0, 0, 0.22);

    &:hover {
      box-shadow: 0 0 15.04px 0.96px rgba(102, 254, 208, 0.41);
    }
    &:active {
      background-color: rgba(0, 215, 192, 0.149);
    }
  }
  &-purple{
    background-image: -moz-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    background-image: -webkit-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    color:$whiteColor;

    &:hover {
      box-shadow: 0 15px 16.92px 1.08px rgba(0, 0, 0, 0.22);
    }
    &:active {
      background: #7d47dd;
    }
  }
  &-dark {
    border:2px solid $darkColor-2;
    color:$whiteColor;

    &:hover {
      border-color:$darkColor-3;
    }
    &:active {
      background-color: $darkColor-3;
    }
  }
  &-border {
    border:2px solid rgba(28,36,62,0.2);
    color:$darkColor-2;

    &:hover {
      border-color:$darkColor-3;
    }
    &:active {
      background-color: $darkColor-3;
    }
  }
  &-border-white {
    border:2px solid rgba(255,255,255,0.1);
    color:$whiteColor;

    &:hover {
      border-color:$darkColor-3;
    }
    &:active {
      background-color: $darkColor-3;
    }
  }
  &-none {
    height:initial;
    padding: 0;
    color:$greenColor-2;
  }
  &-none-white {
    height:initial;
    padding: 0;
    color:$whiteColor;
  }
  &-white-back {
    background-color: rgba(255,255,255,0.1);
    border:2px solid rgba(255,255,255,1);
    color:$whiteColor;
  }
  &-primary-back {
    background-color: rgba(0,215,192,0.1);
    border:2px solid $greenColor-2;
    color:$greenColor-2;
  }
  &-purple-back {
    background-color: rgba(125,71,221,0.1);
    border:2px solid $purpleColor-2;
    color:$purpleColor-2;
  }
  &-green-back {
    background-color: #00d7c0;
    color: $whiteColor;
    box-shadow: 0 15px 16.92px 1.08px rgba(0, 0, 0, 0.22);
  }
  &-disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  &-responsive {
    width:100%;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
  &.loading {
    pointer-events: none;
  }
}

.icon-loading {
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}