@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.billing {
  margin-top: 50px;
  padding-top:30px;
}

.billing-main {
  padding-bottom:50px;
}

.billing-col {
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.recommend-wrap {
  flex:0 1 auto !important;


  .plan-block {
    margin-top: 66px;
  }
}

.recommend-block {
  //max-width: 278px;

  .plan-block {
    width: 278px;
  }
}

.recommend-title {
  text-align: center;
  background-color: rgb(28, 36, 62);
  border-radius: 50px;
  @include font("Rajdhani", 24, 42, $whiteColor, uppercase, 700);
}

.billing-table {
  display: table;
  position: relative;
  width:100%;
  margin: 0 auto;
  max-width: 500px;
}

.table-in {
  display: table-row-group;
  vertical-align: middle;
}

.table-a {
  display: table-row;
  vertical-align: inherit;

  >div{
    display: table-cell;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 20px;
    vertical-align: inherit;
    @include font("Lato", 12, 32, $whiteColor, uppercase, 700);
  }
}

.table-b {
  display: table-row;
  vertical-align: inherit;
  position: relative;
  border-radius: 27px;

  >div{
    display: table-cell;
    vertical-align: inherit;
    padding-right: 25px;
    padding-left: 25px;
    @include font("Lato", 18, 32, $whiteColor, uppercase, 33);
  }
  &:after {
    content:'';
    position: absolute;
    left:0;
    bottom: -12px;
    height: 54px;
    border-radius: 60px;
    right:0px;
    border:1px solid rgba(255,255,255,0.1);
  }
}


.billing-total-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(33, 43, 74);
  height: 78px;
  padding: 0 42px;
}

.billing-date {
  display: flex;
  align-items: center;
  >span {
    margin-left: 10px;
    @include font("Lato", 15, 32, $whiteColor, capitalize, 400);
  }
}

.total-billing-price {
  display: flex;
  align-items: center;

  .text {
    @include font("Lato", 22, 32, $whiteColor, initial, 300);
  }
  .main {
    margin-left: 5px;
    @include font("Lato", 22, 32, $greenColor-2, capitalize, 700);
  }
}

.price-per-image {
  margin-left: 10px;
  padding: 0 11px;
  border-radius: 30px;
  @include font("Lato", 12, 22, $whiteColor, 0, 300);
  border:1px solid rgba(0, 215, 192, 0.1);
}

.billing-controls {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}



.payment-method {
  margin: 34px auto 0;
  max-width: 500px;
}

.payment-title {
  @include font("Lato", 12, 32, $whiteColor, uppercase, 700);
}

.payment-types {
  display: flex;
  align-items: center;
  flex-flow: row;
  border-radius: 27px;
  border:1px solid rgba(255,255,255,0.1);
  height: 52px;
  padding-left: 22px;
}

.payment-type-item {
  position: relative;
  padding-left: 35px;
  i {
    margin-right: 8px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    width: 18px;
    height: 18px;
    border:2px solid rgba(255,255,255,0.15);
    border-radius: 50%;

    &:after {
      content:'';
      display: none;
      top: 4px;
      left: 4px;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #00d7c0;
    }
  }
  &:not(:last-child) {
    margin-right: 42px;
  }
}

.label-main {
  @include font("Lato", 16, 32, $whiteColor, initial, 700);
}

.payment-type-item input:checked ~ .checkmark {
  border-color: #00d7c0;
}

.payment-type-item input:checked ~ .checkmark:after {
  display: block;
}

.promo-block {
  max-width: 500px;
  width: 100%;
  margin: 30px auto -15px;
  background-color: rgba(255,255,255, 0.05);
  border-radius: 50px;
  padding: 15px 25px;

  &-text {
    @include font("Lato", 16, 20, $whiteColor, initial, 400);
  }
  &-count {
    @include font("Lato", 16, 20, $greenColor-2, initial, 700);
  }
}