@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.project-item-list {
  margin-top: 50px;
}

.myprojects {
  padding-bottom: 50px;
}

.myprojects-top {
  padding: 0 30px 0 30px;
  margin-bottom: 45px;
}

.page-title {
  i {
    font-size: 20px;
    margin-right: 20px;
  }
}

.projects-count {
  @extend %inlineMiddle;
  @include font("Lato", 16, 26, $whiteColor, initial, 900);
  background-color: $darkColor-2;
  border-radius: 5px;
  height: 26px;
  padding:0 8px 0 8px;
  margin-left:10px;
}

.project-item {
  position: relative;
  margin-bottom: 30px;
  padding: 13px 30px 12px 30px;
  //overflow: hidden;
  .icon-delete {
    position: absolute;
    right:-10px;
    top:-10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    @extend %userCursor;
    text-align: center;
    line-height: 30px;
    color:$whiteColor;
    font-size: 10px;
    background-color: $darkColor-1;
  }

  &.inprogress {
    color:$purpleColor-2;
    .projectitem-status {
      
      border-color: rgba(125, 71, 221, 0.5);
    }
    &:before {
      background-image: url("../../assets/img/in_progress.svg");
      background-repeat: no-repeat;
    }
  }
  &.ended {
    color:$greenColor-2;
    .projectitem-status {
      
      border-color: rgba(0, 215, 192, 0.5);
    }
    &:before {
      background-image: url("../../assets/img/ended.svg");
      background-repeat: no-repeat;
    }
  }
  &.pending {
    color:#d4dd47;
    .projectitem-status {

      border-color: rgba(212, 221, 71, 0.5);
    }
    &:before {
      background-image: url("../../assets/img/pending.svg");
      background-repeat: no-repeat;
    }
  }
  &:before {
    content:'';
    position: absolute;
    left:0;
    bottom:0;
    width:24px;
    height:24px;
  }
}

.projectitem-name {
  @include font("Lato", 20, 32, $whiteColor, capitalize, 900);
  //max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  width: 100%;
  white-space: nowrap;
}

.projectitem-date {
  margin-top: -11px;
  @include font("Lato", 13, 32, $whiteColor, capitalize, 400);
  opacity: 0.5;
}

.projectitem-status {
  height:28px;
  margin-right: 20px;
  @include font("Lato", 12, 26, currentColor, uppercase, 900);
  padding: 0 10px 0 10px;
  border:1px solid;
  border-radius: 4px;
}

.projectitem-info {
  margin-left: 36px;
  padding-right: 30px;
  text-align: center;
  @include font("Lato", 16, 32, $whiteColor, capitalize, 700);
  border-right:1px solid $darkColor-3;
}

.renders-count-wrapper {
  position: relative;
  padding-top: 35px;
  text-align: center;
}

.close-m {
  position: absolute;
  right:30px;
  top:30px;
  font-size: 18px;
  color: rgba(0,0,0,0.4);
  @extend %userCursor;
}

.welcome-txt {
  @include font("Lato", 18, 28, rgba(0,0,0,0.5), uppercase, 300);
}

.gift-rend-count {
  margin-top: 35px;
  margin-bottom: 25px;
  @extend %inlineMiddle;
}

.briliant-left {
  position: absolute;
  left:-90px;
  top:50px;
}

.briliant-right {
  position: absolute;
  right: -60px;
  top: 112px;
}

.renders-gift-title {
  @include font("Rajdhani", 44, 60, $darkColor, uppercase, 900);
}

.renders-gift-desc {
  margin-top: 16px;
  margin-bottom: 50px;
  @include font("Lato", 22, 36, $darkColor, initial, 300);
  padding-left: 10%;
  padding-right: 10%;
}

.continue {
  line-height: 37px;
  margin-left: 30px;
  cursor: pointer;
  width: 110px;
  text-align: center;
}

.add-project-block {
  position: relative;
  z-index: 1;
  flex:0 0 48%;
  max-width: 48%;
  overflow: hidden;
  display: block;
  margin-bottom: 70px;
  border-radius:20px;
  padding-top: 35px;
  border-top:3px solid #212b4a;

  &:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    height: 275px;
    z-index: -1;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#212b4a), to(transparent));
    background-image: -webkit-linear-gradient(#212b4a, transparent);
    background-image: -moz-linear-gradient(#212b4a, transparent);
    background-image: linear-gradient(#212b4a, transparent);
    -moz-background-size: 3px 100%;
    background-size: 3px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
  }

  &:hover {
    &:before {
      background-image: -webkit-linear-gradient( -90deg, rgba(33,43,74, 1) 0%, rgba(33,43,74, 0.5) 30%, rgba(33,43,74, 0) 100%);
      background-image: linear-gradient( 180deg, rgba(33,43,74, 1) 0%, rgba(33,43,74, 0.5) 30%, rgba(33,43,74, 0) 100%);
      background-size: 100%;
    }
  }
}

.add-project-icon {
  max-width: 180px;
  margin: 0 auto 10px;
}

.add-project-title {
  margin-bottom: 8px;
  @include font("Rajdhani", 30, 32, $whiteColor, capitalize, 700);
}

.add-project-desc {
  padding-left: 13%;
  padding-right: 13%;
  margin-bottom: 40px;
  @include font("Lato", 18, 26, $whiteColor, initial, 300);
}

.help-text {
  margin-top: 30px;
  margin-bottom: 100px;
  @include font("Lato", 14, 26, $whiteColor, initial, 400);

  span, a{
    margin-left: 5px;
    margin-right: 5px;
    @extend %userCursor;
    color:$greenColor-2;
  }
}

.dropdown-wrapper {
  position: relative;

  .main-dropdown {
    right: 0;
    left: inherit;
  }
}

.modal-inner {
  position: relative;
  text-align: center;
  padding: 38px 50px 40px 50px;
}

.sale-text {
  @include font("Rajdhani", 18, 24, rgba(12,16,28,0.5), uppercase, 300);
}

.sale {
  margin: 30px auto 17px;
}

.sale-title {
  @include font("Rajdhani", 40, 52, #0c101c, uppercase, 700);
}

.sale-description {
  padding: 16px 10%;
  @include font("Rajdhani", 20, 26, #0c101c, 0, 300);
}

.close-modal {
  color: #0c101c !important;
}

.left-brilliant {
  position: absolute;
  left: -75px;
  top: 17%;
}

.right-brilliant {
  position: absolute;
  right: -85px;
  top: 27%;
  transform: scale(0.6);
}
