@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.invoice-table {
  width: 100%;
  display: table;
  margin-top: 50px;
  margin-bottom: 100px;
}

.invoices-head {
  background-color: rgba(255,255,255,0.05);
  display: table-header-group;

  >.head-main {
    display: table-row;

    >div {
      display: table-cell;
      padding-left: 25px;
      padding-right: 25px;
      font-size: 12px;
      height: 48px;
      color: $whiteColor;
      text-transform: uppercase;
      font-weight: 800;
      vertical-align: middle;
    }
  }
}

.invoices-body {
  display: table-row-group;
  vertical-align: middle;
}

.invoice-load {
  position: absolute;
  left:calc(50% - 75px)
}

.min-height {
  min-height: 120px;
}

.body-item {
  display: table-row;
  vertical-align: middle;
  height: 50px;

  >div {
    padding-left: 25px;
    padding-right: 25px;
    @include font("Lato", 16, 16, $whiteColor, initial, 400);
    display: table-cell;
    vertical-align: middle;
  }
  a {
    color: $greenColor-1;
    text-transform: uppercase;
  }
  &:not(:last-child) >div{
    border-bottom: 1px dashed rgba(255,255,255,0.1);
  }
}