@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.plan-tabs {
  margin-top: 20px;
}

.plan-block {
  position: relative;
  margin-top: 110px;
  padding-bottom: 120px;
}

.plan-block-main {
  border-top:5px solid #212b4a;
  position: relative;
  min-height: 350px;
  border-radius: 30px;
  padding-top: 54px;
  overflow: hidden;

  &:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:0;
    height: 100%;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#212b4a), to(transparent));
    background-image: -webkit-linear-gradient(#212b4a, transparent);
    background-image:
            -moz-linear-gradient(#212b4a, transparent),
            -moz-linear-gradient(#212b4a, transparent);
    background-image:
            -o-linear-gradient(#212b4a, transparent),
            -o-linear-gradient(#212b4a, transparent);
    background-image:
            linear-gradient(#212b4a, transparent),
            linear-gradient(#212b4a, transparent);
    -moz-background-size: 5px 100%;
    background-size: 5px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
  }
}

.plan-icon {
  position: absolute;
  top: -115px;
  left:0;
  right:0;
  z-index: 1;
  margin: 0 auto;

  &.pro-plus {
    top:-138px;
  }
}

.plan-block-title {
  @include font("Rajdhani", 36, 28, $whiteColor, capitalize, 700);
}

.plan-block-desc {
  margin-top: 15px;
  @include font("Lato", 16, 24, #8c96b1, initial, 300);
  padding-left: 30px;
  padding-right: 30px;
}

.plan-block-info {
  margin-top: 25px;
  @include font("Lato", 14, 24, $whiteColor, uppercase, 900);
}

.plan-block-price {
  margin: 16px 0 30px 0;
  @include font("Lato", 48, 40, $greenColor-1, uppercase, 300);
}

.saled-plan {
  margin-top: -20px;
  margin-bottom: 30px;
  font: 300 1.25rem/1.6 "Lato";
  color: rgba(255,255,255,1);
  //text-decoration: line-through;
}

.pricing-description{
  font: 300 1.375rem/1.6 "Lato";
  color:$whiteColor;
  margin-bottom: 40px;
  padding-left: 21%;
  padding-right: 21%;

  b {
    color:$purpleColor-1;
    margin-left: 4px;
    margin-right: 4px;
    font-weight:700;
  }
}