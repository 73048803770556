//colors

$whiteColor: #fff;
$greenColor-1: #66fed0;
$greenColor-2: #00d7c0;
$purpleColor-1: #8a58e5;
$purpleColor-2: #7d47dd;
$darkColor: #0c101b;
$darkColor-1: #12192d;
$darkColor-2: #1c243e;
$darkColor-3: #212b4a;

//grid dimensions

$rowWidth: 76.88rem;
$columnSpace: 0.9375rem;