@import 'partials/extends';
@import 'partials/mixins';
@import 'partials/vars';

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.page-title {
    @include font("Rajdhani", 32, 32, $whiteColor, initial, 500);
}

.page-title-center {
    @include font("Rajdhani", 46, 46, $whiteColor, capitalize, 500);
}

.modal-field-item {
    margin-bottom: 37px;
}

.form-input {

    > input {
        width: 100%;
        height:51px;
        padding-left: 4px;
        outline: none;
        display: block;
        background-color:transparent;
        border:0;
        border-bottom:1px solid rgba(255,255,255,0.2);
        transition: all 0.25s;
        @include font("Lato", 22, 32, $whiteColor, initial, 300);

        &:focus {
            border-color:rgba(255,255,255,0.5);
        }
    }
    &:hover {

        input {
            border-color:rgba(255,255,255,0.5);
        }
    }
}

.form-label {
    display: block;
    margin-left: 4px;
    @include font("Lato", 12, initial, $greenColor-1, uppercase, 700);
}

/*layout*/

.content-wrapper {
    position: relative;
    background-color: $darkColor-2;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24),inset 0 1px 0 0 rgba(255, 255, 255, 0.04);
}

.main-dropdown {
    position: absolute;
    left:0;
    top:56px;
    background-color: $whiteColor;
    border-radius: 5px;
    overflow: hidden;
    transition:all 0.25s; 
    z-index: 2;
    padding-top: 6px;
    padding-bottom: 6px;
    transform-origin: top;
    animation: togglePopUp 0.15s alternate;

    >a {
        display: block;
        white-space: nowrap;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
        transition:all 0.25s;


        i {
            color:$darkColor-3;
            font-size: 14px;
            margin-right: 15px;
        }
        >span {
            @extend %inlineMiddle;
            @include font("Lato", 16, 48, $darkColor-3, initial, 500);
        }
        &:hover {
            background-color: #e8e9eb;
        }
    }
}

.popupLink {
    @extend %userCursor;
}

.popupLink.active {
    background-color: #e8e9eb;
}

.dropdown-item {
    display: block;
    @extend %userCursor;
    height:48px;
    line-height: 48px;
    min-width:222px;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    transition:all 0.25s; 
    
    i {
        @extend %inlineMiddle;
        margin-right: 10px;
    }
    span {
        @extend %inlineMiddle;
        @include font("Lato", 14, 48, #000, uppercase, 900);
    }
    &:hover {
        background-color: #e8e9eb;
    }
}
/**/

.modal-header {
    height:71px;
    border-bottom:1px solid rgba(255,255,255,0.1);
    padding-left: 20px;
    padding-top: 18px;
}

.modal-title {
    @include font("Lato", 20, 32, $whiteColor, initial, 900);
}

.modal-content {
    @include font("Lato", 18, 30, $whiteColor, initial, 300);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
}

.close-modal {
    position: absolute;
    right:40px;
    top:20px;
    color:$whiteColor;
    @extend %userCursor;
}

.modal_actions {
    padding-bottom: 35px;
    padding-top: 25px;
    padding-left: 5px;
    padding-right: 5px;

    .btn-dark {
        border-color:rgba(255,255,255,0.1);
    }
}

.link {
    @extend %inlineMiddle;

    span {
        @include font("Lato", 14, 14, $greenColor-2, uppercase, 800);
    }
    i {
        margin-left: 10px;
        color:$greenColor-2;
        font-size: 12px;
        line-height: 13px;
    }
}

.flex-middle {
    display: flex;
    align-items: center;
}

@-webkit-keyframes togglePopUp {
    from {transform: scale(0);}
    to {transform: scale(1);}
}

.tour-wrapper {
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0, 0.5);
    z-index: 8;
    mix-blend-mode: multiply;
    pointer-events: none;
}

.tour-effect {
    pointer-events: none;
    position: absolute;
    width: 265px;
    height: 265px;
    background-color: white;
    border-radius: 50%;
    z-index: 8;
    margin-top: -132px;
    margin-left: -132px;
    transition: all 0.3s ease;
}

.get-models-block {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    height:400px;
    background-color:$whiteColor;
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.09),0 0 32px 0 rgba(0, 0, 0, 0.05),inset 0 1px 0 0 rgba(255, 255, 255, 0.04);
    border-radius: 15px;
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 35px;
    background-image: url("../img/figure-d.svg");
    background-repeat: no-repeat;
    background-position: bottom;
}

.get-models-title {
    @include font("Rajdhani", 39, 38, $darkColor-2, uppercase, 400);

    b {
        font-weight: 700;
    }
    &:before {
        content:'';
        display: block;
        margin-bottom: 20px;
        background-color: rgb(0, 215, 192);
        width: 30px;
        height: 5px;
        border-radius: 5px;
    }
}

.field-item {
    margin-top: 20px;
    label {
        display: block;
        @include font("Lato", 14, 32, $whiteColor, capitalize, 700);
    }
}

.loading-toast {

    &:before {
        content: "\f113" !important;
        animation: loadingCircle 1s infinite linear;
        color: white !important;
    }
}

@keyframes loadingCircle {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}