@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '../../../assets/css/partials/mixins.scss';
@import '../../../assets/css/partials/vars.scss';
@import '../../../assets/css/partials/extends.scss';

.viewscollection {
    width: 100%;

    > .item{
        flex:0 0 50%;
        max-width: 50%;

        &.left {
            background-color: $darkColor-1;
            padding-top: 48px;
            padding-bottom: 45px;
            padding-left: 5%;
            padding-right: 5%;
            height:100vh;
        }
        &.right {
            background-color: #1d253f;
            width: calc(100% - 800px);
        }
    }
    .item-inner {
        padding-left: 90px;
        padding-right: 90px;
    }
}

.left-top {
    padding-left: 90px;
}

.viewscollection-title {
    @include font("Rajdhani", 42, 32, $whiteColor, initial, 500);
}

.viewscollection-desc {
    padding-top: 10px;
    padding-bottom: 45px;
    @include font("Lato", 18, 28, $whiteColor, initial, 300);
}

.select-view {
    @extend %inlineMiddle;
    margin-bottom: 32px;
    width: 33.333333%;
}

.check-view-item {
    text-align: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 24px;
    border-radius: 8px;
    height: calc((33.333333% - 24px)*3/4);
    overflow: hidden;
    background-color: $whiteColor;
    box-shadow: 0px 2px 32.9px 2.1px rgba(255, 255, 255, 0.2);
    position: relative;
    @extend %userCursor;

    .checkable {
        position: absolute;
        top:15px;
        right:15px;   
    }
    .check-label {
        position: absolute;
        top:15px;
        right:15px;
        width: 36px;
        height: 36px;
        line-height: 30px;
        border-radius: 50%;
        box-shadow: 0px 0px 15.04px 0.96px rgba(102, 254, 208, 0.41);
        border: 3px solid rgba(0,0,0, 0.3);
        text-align: center;
        transition: all 0.22s;
        @extend %userCursor;
        
        i {
            width: 36px;
            height: 36px;
            font-size: 14px;
            color:$whiteColor;
            text-align: center;
            line-height: 30px;
            visibility: hidden;
            opacity: 0;
            z-index: 1;
            transition: all 0.22s;
        }
    }
    input {
        position: absolute;
        left:0;
        width:100%;
        height:100%;
        top:0;
        opacity: 0;
        @extend %userCursor;
    }
    input:checked + label {
        background-color: $greenColor-2;
        border-color:$greenColor-2;
        i {
            visibility: visible;
            opacity: 1;
        }
    }
}

.checkable-custom {
    position: absolute;
    top:15px;
    right:15px;  
    width: 36px;
    height: 36px;
    line-height: 30px;
    border-radius: 50%;
    box-shadow: 0px 0px 15.04px 0.96px rgba(102, 254, 208, 0.41);
    border: 3px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    transition: all 0.22s;
}

.custom-hide-inp {
    display: block !important;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    opacity: 0;
    @extend %userCursor;
}

.view-icon {
    opacity: 0.5;
}

.check-view-title {
    margin-top: 10px;
    text-align: center;
    @include font("Lato", 20, 32, $whiteColor, capitalize, 700);
}

.views-slider {
    max-width: 680px;
    width:100%;
    margin: 0 auto;
}

.view-slider-item {
    
}

.slider-image {
    border-radius: 20px;
    background-color: $whiteColor;
    text-align: center;
    overflow: hidden;
    
    > img {
        max-height: calc(100vh - 400px);
    }
}

.slider-item-title {
    padding-top: 30px;
    text-align: center;
    @include font("Lato", 36, 32, $whiteColor, capitalize, 900);
}

.viewscollection-actions {
    padding-top: 45px;
    padding-bottom: 30px;
    margin-top: 50px;
    border-top:1px solid $darkColor-2;
}

.add-view-control {
    margin-top: 65px;
    padding-left: 55px;
    padding-right: 55px;

    .field {
        flex:1;
        margin-right: 27px;
    }
}

.default_views {
    margin-left: 43px;
    margin-right: 43px;
    margin-top: 66px;
}