@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';


.purchaseconfirmation {
    margin-bottom: 100px;
}

.confirmation-info {
    display: flex;
    justify-content: center;
    padding-top: 62px;
    padding-bottom: 65px;
}

.confirmation-block {
    margin-bottom: 34px;
}

.confirmation-main {
    flex: 0 0 448px;
    max-width: 448px;
    >i {
        display: block;
        line-height: 60px;
        font-size: 24px;
        width:62px;
        height:62px;
        margin:0 auto;
        color:#20b5af;
        border-radius: 50%;
        text-align: center;
        border:2px solid #20b5af;
        margin-bottom: 18px;
    }
    > .title {
        margin-bottom: 8px;
        @include font("Rajdhani", 30, 32, $whiteColor, uppercase, 700);
    }
    > .description {
        @include font("Lato", 18, 28, $whiteColor, initial, 300);
    }
}

.renders-count {
    margin-top: 30px;
    background-color: rgba(255,255,255, 0.05);
    border-radius: 100px;
    height:66px;
    @extend %inlineMiddle;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 10px;
    
    >span {
        @include font("Lato", 12, 26, $whiteColor, uppercase, 400);
    }
    >div {
        margin-top: -5px;
        @include font("Lato", 14, 26, $whiteColor, uppercase, 700);
        >span {
            font-size: 20px;
            color:#20b5af;
            margin-right: 4px;
            vertical-align: middle;
        }
    }
    i {
        margin-right: 5px;
        font-size: 16px;
        color:$whiteColor;
    }
    small {
        font-size: 100%;
        vertical-align: bottom;
        line-height: 20px;
    }
}
