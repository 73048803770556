@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.project-price {
    position: relative;
    height:50px;
    line-height: 50px;
    border-radius: 5px;
    overflow: hidden;
    background-color: $darkColor-1;
    text-align: center; 
    @include font("Lato", 18, 50, #a6a7ab, initial, 400);
    &:before {
        content:'';
        background-color: $purpleColor-1;
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        width:4px;
    }
    span {
        margin-right: 5px;
        font-weight: 900;
        color:$whiteColor;
    }
}

.generalsetting {
    padding-top: 38px;
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 34px;
}