.loading_content {
  margin: 50px;
  height: 50px;
  color:white;
  text-align: center;

  .loading {

    &:before {
      font-size: 30px;
    }
  }
}

.loading {
  &:before {
    content: "\f113";
    display: block;
    font-size: 20px;
    font-family: iconfont !important;
    animation: loadingCircle 1s infinite linear;
  }
}

@keyframes loadingCircle {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}