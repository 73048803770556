@import '../../../assets/css/partials/vars';
@import '../../../assets/css/partials/mixins';

.field {
  text-align: left;
  margin-bottom: 28px;

  &-bordered {

    input {
      width: 100%;
      height:51px;
      padding-left: 4px;
      outline: none;
      display: block;
      background-color:transparent;
      border:0;
      border-bottom:1px solid rgba(255,255,255,0.2);
      @include font("Lato", 22, 32, $whiteColor, initial, 300);
      transition: all 0.25s;

      &:focus {
        border-color:rgba(255,255,255,0.5);
      }
      &:hover {
        border-color:rgba(255,255,255,0.5);
      }
      &:-internal-autofill-selected {
        background-color: red !important;
        background-image: none !important;
        color: $whiteColor !important;
      }

    }
    label {
      display: block;
      margin-left: 4px;
      @include font("Lato", 12, initial, $greenColor-1, uppercase, 700);
    }
  }

  &-background {
    input {
      width: 100%;
      height:51px;
      padding-left: 15px;
      outline: none;
      display: block;
      background-color:white;
      border:0;
      border-bottom:1px solid rgba(255,255,255,0.2);
      transition: all 0.25s;
      border-radius: 5px;

      @include font("Lato", 16, 36, rgba(0,0,0,1), initial, 500);

      ::-webkit-input-placeholder {
        color:rgba(0,0,0,0.5);
      }
      :-ms-input-placeholder {
        color:rgba(0,0,0,0.5);
      }
      &:focus {
        border-color:rgba(255,255,255,0.5);
      }
    }
    label {
      display: block;
      margin-left: 4px;
      @include font("Lato", 14, 32, $whiteColor, initial, 700);
    }
  }


  &-error {
    position: absolute;
    left:0;
    bottom: -30px;
    white-space: nowrap;
    @include font("Lato", 12, 32, #c62327, capitalize, 700);
  }
  &-link {
    @include font("Lato", 14, 32, $whiteColor, initial, 400);
  }
  &-area {
    width: 100%;
    min-height:151px;
    padding-left: 15px;
    padding-top: 7px;
    resize: vertical;
    outline: none;
    display: block;
    background-color:white;
    border:0;
    border-bottom:1px solid rgba(255,255,255,0.2);
    transition: all 0.25s;
    border-radius: 5px;

    @include font("Lato", 16, 26, rgba(0,0,0,1), initial, 500);

    ::-webkit-input-placeholder {
      color:rgba(0,0,0,0.5);
    }
    :-ms-input-placeholder {
      color:rgba(0,0,0,0.5);
    }
    &:focus {
      border-color:rgba(255,255,255,0.5);
    }
  }
}