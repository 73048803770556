@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.uploadrules {
    margin-bottom: 150px;
}

.uploadrules-content {
    margin-top: 50px;
}

.sidebar-wrapper {
    flex: 0 1 auto !important;
    position: sticky;
    top:0;
}

.anchor-sidebar {
    width: 278px;
    padding-left:32px;
    padding-top: 20px;
    padding-bottom: 20px; 

    a {
        @include font("Rajdhani", 18, 25.5, rgba(255,255,255,0.2), initial, 500);
    }
    .anchor-link {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.anchor-link{
    position: relative;

    &:before {
        content:'';
        position: absolute;
        left: -32px;
        top: 46%;
        width:14px;
        height: 3px;
        background-color: $greenColor-2;
        @extend %hide;
    }
}

.active_link {
    
    &:before {
        @extend %show;
    }
    a {
        color:$whiteColor;
    }
}

.acrticle-sub-links {

    &:not(:last-child) {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
}

.articles {
    background-color: $whiteColor;
    padding-top: 95px;
    padding-bottom: 280px;
}

.article-section {
    >div {
        padding-left: 100px;
        padding-right: 100px;
    }

    img {
        // width:100%;
    }
    .article-txt {
        @include font("Lato", 18, 36, $darkColor, initial, 300);
        padding-top: 20px;
        padding-bottom: 30px;
    }
    ul {
        margin: 0;
        padding: 0;
    }
}

.article-title {
    @include font("Rajdhani", 42, 54, $darkColor, initial, 700);
    margin-bottom: 20px;

    &:after {
        content:'';
        margin-top: 25px;
        display: block;
        width: 44px;
        height: 3px;
        background-color:$greenColor-2;
    }
}

  

.article-sub-desc {
    @include font("Lato", 24, 36, rgba(12,16,27,0.65), initial, 300);
}