@import './partials/extends.scss';
@import './partials/mixins.scss';
@import './partials/vars.scss';
@import './grid.scss';

html {
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: $darkColor-1;
    //overflow-y: scroll;

    &.disableScroll {
        overflow: hidden;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

a {
    text-decoration: none;
}

img {
    display: inline-block;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}

main {
    padding-top: 20px;
    min-height:calc(100vh - 235px);
}

.page {
    background-color: $darkColor-1;
}

.disablePointerEvents {
    pointer-events: none;
}