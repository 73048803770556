@import '../../../../assets/css/partials/mixins.scss';
@import '../../../../assets/css/partials/vars.scss';
@import '../../../../assets/css/partials/extends.scss';

.views{
  flex: 0 0 666px;

  .stylebox-subtitle {
    border-radius: 30px 0px 0px 30px;
  }
}

.main-views {
  border-radius: 8px;
  background-color: $darkColor-1;
  padding: 12px;
  padding-top:25px; 
  margin-right: 30px;
}

.col-view {
  @extend %inlineMiddle;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
}

.add-new-views {

  text-align: center;

  .title {
    @include font("Lato", 16, 32, $whiteColor, capitalize, 500);
  }
}

.add-new {
  @extend %userCursor;
  border:2px dashed rgba(255,255,255, 0.1);
  border-radius: 5px;
  font-size: 24px;
  width:129px;
  height:129px;
  line-height: 125px;
  color:$whiteColor;
}

.added-view-item {
  text-align: center;

  .title {
    @include font("Lato", 16, 32, $whiteColor, capitalize, 500);
  }
}

.added-img {
  position: relative;
  width:129px;
  height:129px;
  line-height: 125px;
  border-radius: 5px;
  @extend %userCursor;
  background-color: $whiteColor;

  .delete-view {
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top:-15px;
    right:-15px;
    font-size: 10px;
    color:$whiteColor;
    border-radius: 50%;
    background-color: $darkColor-1;
    @extend %userCursor;
    z-index: 1;
  }
}
