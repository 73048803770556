@import '../../../../assets/css/partials/mixins.scss';
@import '../../../../assets/css/partials/vars.scss';
@import '../../../../assets/css/partials/extends.scss';

.materials{
  flex:1 1;
  padding-right: 52px;
  .stylebox-subtitle {
    border-radius: 0 30px 30px 0;
  }
}

.materials-col {
  display: flex;
  flex-flow: row wrap;
}

.materials-select-wrapper {
  margin-bottom: 34px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  >label {
    width: 125px;
    padding-left: 30px;
    display: inline-block;
    vertical-align: top;
    @include font("Lato", 16, 32, $whiteColor, capitalize, 500);
  }
  .Select {
    @extend %inlineMiddle;
    width: 337px;
  }
  .Select-placeholder, .Select--single > .Select-control .Select-value {
    line-height:44px;
    @include font("Lato", 16, 44, #15151d, initial, 600);

  }
}

.delete-material {
  @extend %inlineMiddle;
  margin-left: 10px;

  i {
    @extend %userCursor;
    color: $whiteColor;
    font-size: 10px;
  }
}

.Select-clear-zone {
  display: none;
}

.add-new-material {
  margin-left: 123px;
  margin-top: -13px;
}