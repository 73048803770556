.uploadrulesstep {

    .uploadrules {
        margin-bottom: 30px;
    }
    .privacy-check {
        margin-bottom: 70px;
    }
    .uploadrules {

        >.text-center {
            display: none;
        }
    }
    .upload-step-actions {
        margin-top: 0px;
        margin-bottom: 200px;
    }
}

.modal-attention {
    padding-top: 30px;
}

.attention-modal {
    
}

.close-wrapp {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 10px;
    background-color: #12192d;
}