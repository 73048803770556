@font-face {
  font-family: 'iconfont';
  src: url('../../../assets/icons/iconfont.woff2') format('woff2'), url('../../../assets/icons/iconfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon {
  line-height: 1;
}

.icon:before {
  font-family: 'iconfont' !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: middle;
}
.icon-arrow-bottom:before {
  content: "\f101";
}
.icon-arrow-left:before {
  content: "\f102";
}
.icon-arrow-right:before {
  content: "\f103";
}
.icon-arrow-top:before {
  content: "\f104";
}
.icon-attention:before {
  content: "\f105";
}
.icon-check:before {
  content: "\f106";
}
.icon-delete:before {
  content: "\f107";
}
.icon-edit:before {
  content: "\f108";
}
.icon-email:before {
  content: "\f109";
}
.icon-facebook:before {
  content: "\f10a";
}
.icon-files:before {
  content: "\f10b";
}
.icon-folder:before {
  content: "\f10c";
}
.icon-g-plus:before {
  content: "\f10d";
}
.icon-help-icon:before {
  content: "\f10e";
}
.icon-help:before {
  content: "\f10f";
}
.icon-images:before {
  content: "\f110";
}
.icon-insta:before {
  content: "\f111";
}
.icon-learn:before {
  content: "\f112";
}
.icon-loading:before {
  content: "\f113";
}
.icon-lock:before {
  content: "\f114";
}
.icon-logout:before {
  content: "\f115";
}
.icon-msg:before {
  content: "\f116";
}
.icon-pin:before {
  content: "\f117";
}
.icon-plus:before {
  content: "\f118";
}
.icon-rules:before {
  content: "\f119";
}
.icon-settings:before {
  content: "\f11a";
}
.icon-star:before {
  content: "\f11b";
}
.icon-upload:before {
  content: "\f11c";
}
.icon-user:before {
  content: "\f11d";
}
.icon-zoom:before {
  content: "\f11e";
}