@import '../../../assets/css/partials/extends.scss';
@import '../../../assets/css/partials/mixins.scss';
@import '../../../assets/css/partials/vars.scss';


footer {
  position: relative;
  height:84px;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 730px;
    z-index: -1;
    background-image: url("../../../assets/img/footer_trinag_bg.png");
  }
}

#footer-left {
  display: flex;
  align-items: center;
}

#footer-logo {
  img {
    width: 32px;
  }
}

.footer-main {
  padding: 0 15px 0 15px;
}

.footerleftinfo {
  @include font("Lato", 13, 20, #8c96b1, initial, 300);
  margin-left: 18px;

  >div {
    >a {
      color:#8c96b1;

      &:after {
        content: '';
        margin: 0 6px;
        width: 2px;
        height: 2px;
        background-color: currentColor;
        @extend %inlineMiddle;
      }
    }
  }
}

#footer-socials {

  a {
    margin: 0 9px 0 9px;
  }
}

#footer-right {
  @include font("Lato", 13, 20, #8c96b1, initial, 300);
}

#lang {
  position: relative;
  color:#8c96b1;
}

#main-lang {
  text-transform: capitalize;
  @extend %userCursor;

  &:before {
    content: '';
    @extend %inlineMiddle;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: -2px;
    background-size: 15px;
    background-image: url("../../../assets/img/lang.svg");
  }
  &:after {
    content: '';
    @extend %inlineMiddle;
    width: 8px;
    height: 5px;
    margin-left: 5px;
    margin-top: -1px;
    background-image: url("../../../assets/img/drop-arrow.svg");
  }
}

#lang-drop {
  position: absolute;
  left: 50%;
  bottom: 110%;
  background-color: #0f1424;
  border: 1px solid rgba(255,255,255,0.1);
  text-align: center;
  padding: 7px;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;

  div {
    display: block;
    font: 300 .8125rem/1.5 "Lato";
    color: #8c96b1;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}