@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';

.setmaterialsviews {
    padding-bottom: 150px;
}

.selectedfiles-wrapper {
    position: relative;
    padding: 45px 70px;
    margin-bottom: 50px;
    
    &:before {
        content:'';
        position: absolute;
        left:30px;
        right:30px;
        top:24px;
        bottom:24px;
        border:1px dashed rgba(255,255,255,0.1);
        pointer-events: none;
    }

    .btn {
        margin-left: 48px;
    }
}

.selectedfiles-icon {
    position: absolute;
    left:50%;
    top:15px;
    transform: translateX(-50%);
    @include font("Lato", 22, 40, $whiteColor, initial, 600);

    .icon-files {
        
        font-size: 34px;
    }
    i {
        color:$whiteColor;
    }
    .icon-check {
        position: absolute;
        border-radius: 50%;
        box-shadow: 0px 0px 15.04px 0.96px rgba(102, 254, 208, 0.41);
        background-color: $greenColor-1;
        text-align: center;
        line-height: 18px;
        right: -7px;
        bottom: -3px;
        width: 14px;
        height: 14px;
        font-size: 5px;
        line-height: 14px;
    }
}

.selectedfiles-info {
    @extend %inlineMiddle;
    margin-left: 30px;

    div {
        @include font("Rajdhani", 30, 32, $whiteColor, initial, 700);
    }
}

.view-global-action {
    margin-bottom: 30px;
    > span {
        margin-left: 20px;
        margin-right: 20px;
        @include font("Lato", 18, 28, $whiteColor, initial, 300);
    }
}

.progress-files {
    position: relative;
    @extend %inlineMiddle;
    max-width: 70px;
}

.dropdown {
    position: relative;
    @extend %inlineMiddle;
}

.modal-top {
    color:$whiteColor;
    border-bottom:1px solid rgba(255,255,255, 0.1);
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;

    i {
        @extend %userCursor;
    }
}

.modal-main {
    padding-bottom: 35px;
}

.modal-title {
    @include font("Lato", 20, 30, $whiteColor, initial, 900);
}

.favorites-content {
    max-height: 405px;
    margin-top:20px;
    padding-left: 25px;
    padding-right: 25px;
}

.fav-check {
    position: relative;
    height:60px;
    padding-top: 6px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    label {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        @extend %userCursor;
        height:47px;
        border-radius: 5px;
        @include font("Lato", 18, 30, $whiteColor, initial, 400);
        line-height:47px;
        &:before {
            content:"\f106";
            margin-top: -2px;
            @extend %inlineMiddle;
            font-family: iconfont !important;
            font-style: normal;
            font-weight: normal !important;
            font-size: 0px;
            line-height: 16px;
            text-align: center;
            color:$whiteColor;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border-radius: 3px;
            margin-right:12px;
            transition: all 0.22s;
            background-color: $whiteColor;
        }
    }
    input {
        display: none;
    }
    input:checked + label {
        background-color: #12192d;
        &:before {
            background-color: $greenColor-2;
            font-size: 8px;
        }
    }
    &:hover {

        label {
            background-color: #12192d;
        }
        .delete-fav {
            @extend %show;
        }
    }
    
}

.help-box {
    vertical-align: middle;
    @include font("Lato", 15, 30, $whiteColor, uppercase, 500);
}

.box-tooltip {

    .__react_component_tooltip {
        width:340px;
    }
}

.delete-fav {
    position: absolute;
    right: 20px;
    top: 16px;
    @extend %userCursor;
    @extend %hide;
    transition: all 0.22s;
    color:$whiteColor;
}

.modal-actions {
    padding-top: 35px;
    padding-left: 25px;
    padding-right: 25px;
    border-top:1px solid rgba(255,255,255, 0.1);
}

.view-top-control {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 70px;
}

.stylebox-help {
    >span {
        vertical-align: middle;
        margin-left:6px;
        @include font("Lato", 15, 32, rgba(255,255,255,0.8), uppercase, 500);
    }
}

.no-stylebox {
    max-width: 380px;
    margin:60px auto;
    @include font("Rajdhani", 30, 32, $whiteColor, capitalize, 700);
}

.no-stylebox-title {
    @include font("Rajdhani", 30, 32, $whiteColor, initial, 700);
}

.no-stylebox-desc {
    @include font("Lato", 18, 28, $whiteColor, initial, 300);
}