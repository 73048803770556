@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.general-settings {
    max-width: 460px;
    width:100%;
    margin: 0 auto;
    padding-bottom: 80px;
    text-align: center;
}

.step1 {
    .generalsetting {
        background-color: $darkColor-2;
        padding-bottom: 35px;
    }
    
}

.general-top {
    margin-bottom: 54px;
    >.min-title {
        margin-bottom: 7px;
    }
}