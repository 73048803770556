@import '../../../assets/css/partials/mixins.scss';
@import '../../../assets/css/partials/vars.scss';
@import '../../../assets/css/partials/extends.scss';

.checkout-wrapper {
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    z-index: 8;
}

.checkout-main {
    border-radius: 10px 10px 0 0;
    height: 84px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $whiteColor;

    >.row {
        height: 100%;
    }
}

.upload-fils_info {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    background-color: #f3f3f4;
    padding-left: 12px;
    padding-right: 12px;
    height:46px;
    border-radius: 5px;
    >div {
        padding-left: 12px;
        padding-right: 12px;
    }
    i {
        font-size: 22px;
        color:#c6c7cc;
    }
    span{
        margin-left: 10px;
        @include font("Lato", 16, 32, $darkColor-1, initial, 500);
    }
}

.price-per-picture {
    position: relative;
    border-radius: 5px;
    margin-right: 20px;
    height:46px;
    overflow: hidden;
    background-color: #f3f3f4;
    padding-right: 20px;
    padding-left: 24px;
    @include font("Lato", 18, 46, #888c96, initial, 400);

    span {
        font-weight: 900;
        color:$darkColor-1;
        margin-right: 5px;
    }
    &:before {
        content:'';
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        width:4px;
        background-image: -moz-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
        background-image: -webkit-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
        background-image: -ms-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    }
}

.price-info {
    @include font("Lato", 25, 25, $darkColor-1, uppercase, 900);
    span {
        display: block;
        font-size: 13px;
        color:rgba(18,25,45,0.5);
        font-weight: 700;
    }
    &:not(:last-child) {
        margin-right: 32px;
    }
}