@import './partials/extends.scss';
@import './partials/mixins.scss';
@import './partials/vars.scss';

.modal-field-item {
    .Select--single {

        .Select-control {
            height:51px;
            background: none !important;
            background-color: transparent !important;
            border:0 !important;
            border-color:transparent !important;
            border-bottom:1px solid rgba(255,255,255,0.2) !important;
            border-radius: 0 !important;
            outline: none !important;
            text-align: left;
            box-shadow: none !important;
            transition: all 0.25s;
        }
        &:hover {
     
            .Select-control {
                border-color:rgba(255,255,255,0.5) !important;
            }
        }
        &:focus {
     
            .Select-control {
                border-color:rgba(255,255,255,0.5) !important;
            }
        }
        .Select-arrow {
            color:$whiteColor;
        }
     }   
     
.Select-placeholder, .Select--single > .Select-control .Select-value {
    padding-left: 4px;
    top:12px;
    @include font("Lato", 22, 28, $whiteColor, initial, 300);
 }
 
 .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    @include font("Lato", 22, 28, $whiteColor, initial, 300);
 }  
}

.Select-menu-outer {
   border-radius: 10px;
   overflow: hidden;
   border:0;
   outline: none;
   text-align: left;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24);
}

.Select-menu {
   border:0;
   outline: none;
}

.Select-option.is-focused {
   background-color: rgba(0,215,192,0.1);
}

.Select-option {
   @include font("Lato", 18, 32, $darkColor-1, initial, 300);
}

.Select--multi .Select-value-icon {
    float:right;
    font-size: 0;
    border:0;
    background-color: transparent !important;
    padding: 0;
    margin-top: 2px;
    margin-left: 5px;

    &:before {
        content: "\f107";
        font-family: iconfont;
        vertical-align: middle;
        font-size: 10px;
        color:rgba(18,25,45, 0.4);
    }
}

.tags-main {
    .Select--multi .Select-value {
        background-color: rgba(28, 36, 62, 0.1);
        height: 28px;
        border-radius: 28px;
        margin-right: 8px;
        color:#15151d;
    }
    .Select-control {
        padding-left: 15px;
    }
}

.Select--multi.is-focused {

    .Select-control {
        border:0 !important;
    }
}

.Select--multi .Select-value {
    border:0;
    margin-top: 8px;
    line-height: 26px;
    padding-left: 15px;
    padding-right: 15px;
    color:#15151d;
}

.Select-control {
    height:46px;
    line-height: 38px;
    border:0;
}


/**/
.CircularProgressbar .CircularProgressbar-text {
    fill:none;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke:rgba(255,255,255,0.1);
}

.CircularProgressbar .CircularProgressbar-path {
    stroke:$greenColor-1;
}

.Select-arrow {
    border:0;
    &:before {
        content: "\f101";
        font-size: 8px;
        font-family: iconfont;
        font-style: normal;
        font-weight: normal;
        vertical-align: middle;
    }
}

/**/

.item.right {
    .react-tabs__tab-list {
        background-color: rgba(255, 255, 255, 0.05);
        height: 94px;
        border:0;
        padding-left: 55px;
        padding-top: 3px;
    }

    .react-tabs__tab {
        padding: 26px 5px;
        margin-right: 38px;
        @include font("Lato", 18, 32, $whiteColor, initial, 700);
    }

    .react-tabs__tab--selected {
        color:$greenColor-2;
        background: none;
        border: 0;
        border-bottom:3px solid $greenColor-2;
    }
}

.plan-tabs {
    .react-tabs__tab-list {
        //background-color: rgba(255, 255, 255, 0.05);
        padding: 0;
        margin: 0;
        border-radius: 50px;
        overflow: hidden;
        border:2px solid rgba(28, 36, 62, 1);
        @extend %inlineMiddle;
    }
    .react-tabs__tab {
        @include font("Lato", 12, 42, $whiteColor, uppercase, 900);
        border:0;
        border-radius: 0;
        bottom:0;
        padding: 0 20px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24),inset 0 1px 0 0 rgba(255, 255, 255, 0.04);

        &:focus:after{
            display: none;
        }
        &:nth-child(2) {
            border-left:2px solid rgb(28, 36, 62);
            border-right:2px solid rgb(28, 36, 62);
        }
    }
    .react-tabs__tab--selected {
        background-color: rgba(28, 36, 62, 1);
    }
}


.Select-input {
    background-color: transparent !important;
}

.Select-control > *:last-child {
    padding-right: 25px;
}

.toast-message {
    width: initial !important;
    left: 27px !important;
    bottom: 10px !important;
}

.Select.is-open > .Select-control .Select-arrow {
    top:0 !important;
}

.Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
}

/*success*/

.toast-message .success {
    border-radius: 8px;
    background-color: rgb(32, 42, 73);
    height: 60px;
    min-height: 60px;
    padding: 13px 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24);
    @include font("Lato", 15, 32, $whiteColor, initial, 700);
    text-align: center;
    button {
        display: none;
    }

    &:before {
        content: "\f106";
        font-weight: normal;
        font-family: iconfont;
        font-size: 11px;
        width:32px;
        height:32px;
        border-radius: 50%;
        line-height: 34px;
        margin-right: 15px;
        color:$greenColor-2;
        background-color:rgba(0,215,192,0.1);
    }
}

/*error*/

.toast-message .error {
    border-radius: 8px;
    background-color: rgb(32, 42, 73);
    height: 60px;
    min-height: 60px;
    padding: 13px 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24);
    @include font("Lato", 15, 32, $whiteColor, initial, 700);
    text-align: center;
    button {
        display: none;
    }

    &:before {
        content: "\f107";
        font-weight: normal;
        font-family: iconfont;
        font-size: 12px;
        width:32px;
        height:32px;
        border-radius: 50%;
        line-height: 32px;
        margin-right: 15px;
        color:#f73872;
        background-color:rgba(247,56,114,0.1);
    }
}

.toast-message .warn {
    border-radius: 8px;
    background-color: rgb(32, 42, 73);
    height: 60px;
    min-height: 60px;
    padding: 13px 20px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24);
    @include font("Lato", 15, 32, $whiteColor, initial, 700);
    text-align: center;
    button {
        display: none;
    }

    &:before {
        content: "\f105";
        font-weight: normal;
        font-family: iconfont;
        font-size: 14px;
        width:32px;
        height:32px;
        border-radius: 50%;
        line-height: 32px;
        margin-right: 15px;
        color:#d4dd47;
        background-color:rgba(212,221,71,0.1);
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: all 0.2s ease;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Content {
    opacity: 0;
    //transform: scale(0);
    transition: all 0.3s ease;
}

.ReactModal__Content--after-open {
    opacity: 1;
    //transform: scale(1);
}

.ReactModal__Content--before-close {
    opacity: 0;
}


.__react_component_tooltip {
    text-align: left;
    width: 263px;
    pointer-events: auto !important;
    padding:22px 30px 22px 30px;
    background-color: #1c243e !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),0 4px 42px 0 rgba(0, 0, 0, 0.24),inset 0 1px 0px 0 rgba(255, 255, 255, 0.04);
    border-radius:5px;  
    @include font("Lato", 16, 30, $whiteColor, initial, 400);
}

.__react_component_tooltip.place-left {
    margin-left:-20px !important;
}

.__react_component_tooltip.show {
    opacity: 1;
}

.__react_component_tooltip.place-right {
    margin-left:20px !important;
}

.tooltip-wrap {
    margin-left:5px;
    margin-right:5px;
    i {
        margin-left:5px;
        margin-right:5px;
        font-size: 14px;
        color:rgba(255,255,255,0.8);
        @extend %userCursor;
    }
}

.__react_component_tooltip.place-left:after{
    border-left-color: #1c243e !important;
}

.__react_component_tooltip.place-right:after{
    border-right-color: #1c243e !important;
}

.__react_component_tooltip.place-bottom:after {
    border-bottom-color: #1c243e !important;
}

.slick-prev:before, .slick-next:before{
     font-family: iconfont !important;
}

.slick-prev:before{
    content: "\F102";
}

.slick-next:before{
    content: "\F103";
}