@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';

.request-model{
  margin-top: 10px;
  border-radius: 4px;
  background-color: rgb(28, 36, 62);
  box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.09),0px 0px 32px 0px rgba(0, 0, 0, 0.05),inset 0px 1px 0px 0px rgba(255, 255, 255, 0.04);
  overflow: hidden;
  margin-bottom: 150px;
}

.request-model-title {
  @include font("Lato", 24, 32, $whiteColor, initial, 800);
  
  &:before {
    content: '';
    display: block;
    background-color: rgb(0, 215, 192);
    width: 30px;
    height: 5px;
    border-radius: 5px;
    margin-bottom: 14px;
  }
}

.request-model-left {
  flex:0 0 520px;
  max-width: 520px;
  padding: 40px;
  background-color: $darkColor-2;
}

.request-model-right {
  text-align: center;
  //@include font("Rajdhani", 30, 32, $whiteColor, uppercase, 700);
  background-color: #192138;
  padding-top: 68px;
  padding-left: 35px;
  padding-right: 35px;
}

.request-model-desc {
  margin-top: 5px;
  margin-bottom: 15px;
  @include font("Lato", 18, 30, $whiteColor, initial, 300);
}

.request-model-txt {
  margin-top: 20px;
  @include font("Lato", 18, 28, $whiteColor, initial, 400);
}

#choose-files {
  visibility: hidden;
  height: 0;
}

.choose-files {
  i {
    color:$greenColor-2;
    margin-right: 7px;
  }
  span {
    @include font("Lato", 14, 28, $greenColor-2, uppercase, 800);
  }
  @extend %userCursor;
}

.field-input-custom {
  min-height: 41px;
  border-radius: 5px;
  background-color: $whiteColor;
}


.attached-file {
  position: relative;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: rgba(28, 36, 62, 0.1);
  height: 28px;
  border-radius: 28px;
  margin-left: 8px;
  color: #15151d;
  @extend %inlineMiddle;
  line-height: 26px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Select-value-label {
  display: block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-value-icon {
  position: absolute;
  right: 10px;
  top: 0;
  @extend %userCursor;
  &:before {
    content: "\F107";
    font-family: iconfont;
    vertical-align: middle;
    font-size: 10px;
    color: rgba(18, 25, 45, 0.4);
  }
}

.input-error {
  //height: 28px;
  @include font("Lato", 14, 28, red, initial, 400);
}