@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
@import '../../assets/css/partials/extends.scss';


.succesmessage {
    margin-bottom: 50px;
}

.confirmation-info {
    display: flex;
    justify-content: center;
    padding-top: 62px;
    padding-bottom: 65px;
}

.confirmation-block {
    margin-bottom: 34px;
}

.confirmation-main {
    flex: 0 0 448px;
    max-width: 448px;
    >i {
        display: block;
        line-height: 60px;
        font-size: 24px;
        width:62px;
        height:62px;
        margin: 0 auto 18px;
        color:#20b5af;
        border-radius: 50%;
        text-align: center;
        border:2px solid #20b5af;
    }
    > .title {
        margin-bottom: 8px;
        @include font("Rajdhani", 30, 32, $whiteColor, uppercase, 700);
    }
    > .description {
        @include font("Lato", 18, 28, $whiteColor, initial, 300);
    }
}