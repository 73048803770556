@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';

.scene-items {
    margin-bottom: 70px;
}

.sceneitem {
    background-color: $darkColor-2;
    padding:18px 30px 30px 30px;
    margin-bottom: 40px;
}

.sceneitem-title {
    @include font("Rajdhani", 30, 32, $whiteColor, initial, 700);
}

.slick-slider {
   margin-top:20px;
}

.slider-item {
    position: relative;
    margin-left:12px;
    margin-right:12px;
    height:193px;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    background-color: $whiteColor;
    @extend %userCursor;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:hover {
        .item-hover {
            @extend %show;
        }
    }
}

.item-hover {
    position: absolute;
    left:0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width:100%;
    height:100%;
    transition: all 0.25s;
    background-color: rgba(28,36,62,0.5);
    color:$whiteColor;
    @extend %hide;

    i {
        font-size: 36px;
    }
    span {
        @include font("Lato", 14, 32, $whiteColor, uppercase, 900);
    }
}

.video-scene {
    padding: 20px 30px 54px 30px;
    margin-bottom: 75px;
}

.sceneitem-img {
    position: relative;
    margin-top: 23px;
    @extend %userCursor;

    >img {
        border-radius:5px;
    }
    &:hover {
        .hidden-video {
            @extend %show;
        }
    }
    video {
        width: 100%;
    }
}

.select-btn-wrapper {
    position: absolute;
    bottom:-25px;
    left:50%;
    width:100%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
}

.hidden-video {
    position: absolute;
    left: 0;
    right: 0;
    @extend %hide;
    transition:all 0.25s ease;
    -webkit-transition:all 0.25s ease;
    -moz-transition:all 0.25s ease;
}

.close-video {
    position: absolute;
    color:$whiteColor;
    right:-10px;
    top:-10px;
    @extend %userCursor;
}

.new-video {
    height: 180px;
    overflow: hidden;

    video {
        margin-top: -70px;
    }
}