@import '../../../assets/css/partials/extends.scss';
@import '../../../assets/css/partials/mixins.scss';
@import '../../../assets/css/partials/vars.scss';

header {
    height:151px;
    padding-top: 36px;
}

.header-right {
    .btn {
        margin-left: 20px;
    }
}

#logo {
    display: block;
    width: 170px;
}

.header-link {
    margin-right: 30px;
    display: inline-flex;
    align-items: center;
    @include font("Lato", 12, 24, $whiteColor, uppercase, 900);

    i {
        margin-right: 11px;
        font-size: 18px;
    }
    img {
       width: 20px;
    }
}

.account-dropdown {
    margin-left: 38px;
    position: relative;

    .main-dropdown {
        left:initial;
        right:0;

        img {
            width: 14px;
        }
    }
}

.account-drop {
    
    display: flex;
    align-items: center;
    @extend %userCursor;

    i {
        margin-left: 16px;
        font-size: 6px;
        color:$whiteColor;
    }
}

.account-name {
    @include font("Lato", 18, 24, $whiteColor, capitalize, 900);
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.account-img {
    width:42px;
    height: 42px;
    border-radius: 50%;
    border:1px solid rgba(255,255,255,0.5);
}

.dropdown {
    display: inline-block;
}

.dropdown__content {
    display: none;
    position: absolute;
}

.dropdown--active .dropdown__content {
    display: block;
}

.balance-title {
    flex: 0 1 100%;
    margin-bottom: 3px;
    @include font("Lato", 10, 10, #00d7c0, uppercase, 700);
}

.balance {
    text-align: left;
    margin-right: 40px;
    height: 50px;
    border-radius: 14px;
    background-color: rgba(255,255,255,0.05);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;

    i {
        margin-right: 4px;
        opacity: 0.6;
    }
    span {
        @include font("Lato", 16, 16, $whiteColor, initial, 700);
    }
}

.balance-item {
    display: inline-flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 15px;
    }
}

.balance-items {
    display:flex;
    flex-flow: row wrap;
}

.gift {
    margin-right: 65px;
    &-icon {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        line-height: 44px;
        text-align: center;
        background-image: -moz-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
        background-image: -webkit-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
        background-image: -ms-linear-gradient( 45deg, rgb(125,71,221) 0%, rgb(161,115,242) 100%);
    }
    &-info {
        margin-left: 12px;
    }
    &-title {
        span {
            @include font("Lato", 14, 14, $whiteColor, uppercase, 400);
        }
    }
    &-percent {
        @include font("Lato", 14, 22, $whiteColor, initial, 800);

        span {
            opacity: 0.5;
        }
    }
    &-progress {
        margin-top: 4px;
        height: 6px;
        background-color: rgba(255, 255, 255, 0.2);
        width: 140px;
        border-radius: 30px;
        overflow: hidden;

        &-in {
            height: 100%;
            background-image: -moz-linear-gradient( 180deg, rgb(161,115,242) 0%, rgb(125,71,221) 100%);
            background-image: -webkit-linear-gradient( 180deg, rgb(161,115,242) 0%, rgb(125,71,221) 100%);
            background-image: -ms-linear-gradient( 180deg, rgb(161,115,242) 0%, rgb(125,71,221) 100%);
        }
    }
}