@import '../../assets/css/partials/extends.scss';
@import '../../assets/css/partials/mixins.scss';
@import '../../assets/css/partials/vars.scss';
.examples-list {
    margin-top: 50px;
    margin-bottom: 150px;
}

.example-item {
    height:275px;
    position: relative;
    margin-bottom: 30px;

    .name {
        position: absolute;
        right:18px;
        bottom:18px;
        height:25px;
        background-color: rgba(18,25,45,0.4);
        border-radius: 25px;
        padding-left: 10px;
        padding-right: 10px;
        @include font("Lato", 12, 25, $whiteColor, uppercase, 900);
    }
}
